import { InputLabel } from "@material-ui/core";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";

export default function MultipleInput(props) {
  return (
    <>
      <InputLabel>{props.label}</InputLabel>
      <Tags
        className="col-md-12"
        name={props.name}
        whitelist={props.options}
        settings={{
          skipInvalid: true,
          dropdown: {
            searchKeys: "label",
            closeOnSelect: false,
            enabled: 0,
            highlightFirst: true,
          },
          originalInputValueFormat: (valuesArr) =>
            valuesArr
              .map((item) => {
                return item.id;
              })
              .join(","),
          validate: (item) => {
            if (!item.id) return false;
            return true;
          },
        }}
        disabled={props.disabled}
        value={props.value || ""}
        onChange={props.onChange}
        onInput={props.onInput}
      />
    </>
  );
}
