import moment from "moment";
import { createFiltersQueryString } from "./functions";

let url = process.env.REACT_APP_API_URL;
export let siteUrl = process.env.REACT_APP_SITE_URL;
const limit = 4;

async function logError(err) {
  if(process.env.REACT_APP_ENV !== "production") {
    console.error(err)
  }

  return;
}

export async function getFestivals() {
  try {
    const festivals = await fetch(url + "/festival/landing/" + limit);
    return await festivals.json();
  } catch (error) {
    logError(error);
    throw error;
  }
}
export async function getFestivalsYear(year) {
  try {
    let festivals = await fetch(`${url}/festival/years/${year}`);
    return await festivals.json();
  } catch (error) {
    logError(error);
  }
}
export async function getEvents({ id, type, isPast }) {
  try {
    const filters = createFiltersQueryString({ id, type, isPast });
    var events = await fetch(url + `/event${filters}`);
    events = await events.json();
    for (let event of events.events) {
      event.sDate=moment(event.sDate).utc().format('llll')
    }
    return events;
  } catch (error) {
    logError(error);
    throw error;
  }
}
export async function searchEventsDebounced(query) {
  try {
    const res = await fetch(`${url}/event/list/search?query=${query}`);
    return await res.json();
  } catch (error) {
    logError(error);
  }
}
// export async function getParades() {
//   try {
//     const parades = await fetch(`${url}/festival/parade`);
//     return await parades.json();
//   } catch (error) {
//     logError(error);
//   }
// }
export async function getBlogs(query) {
  try {
    if (!query.startsWith("?")) {
      query = "?" + query
    }
    var blogs = await fetch(`${url}/blog${query}`);
    blogs = await blogs.json();
    return blogs;
  } catch (error) {
    logError(error);
    throw error;
  }
}
export async function getNews() {
  try {
    var news = await fetch(url + "/news");
    news = await news.json();
    return news;
  } catch (error) {
    logError(error);
    throw error;
  }
}

// unused!
export async function getCostumeBands() {
  try {
    const costumeBands = await fetch(`${url}/organization/landing/4`);
    if(costumeBands.status==404) {
      window.alert('Could not get data!');
      return null;
    }
    return await costumeBands.json();
  } catch (error) {
    logError(error);
  }
}
export async function getDesigners(queryString) {
  try {
    var designers = await fetch(`${url}/organization/search/designer${queryString || ""}`);
    if(designers.status==404) {
      window.alert('Could not get data!');
      return null;
    }
    return await designers.json();
  } catch (error) {
    logError(error);
  }
}

// unused!
export async function getAwards() {
  try {
    const awards = await fetch(`${url}/award`);
    if(awards.status==404) {
      window.alert('Could not get data!');
      return null;
    }
    return awards.json();
  } catch (error) {
    logError(error);
  }
}
export async function getCarouselImages() {
  try {
    var images = await fetch(`${url}/slide-show`);
    images = await images.json();
    return images;
  } catch (error) {
    logError(error);
  }
}
export async function getFaqs() {
  try {
    const faqs = await fetch(`${url}/faq`);
    return await faqs.json();
  } catch (error) {
    logError(error);
  }
}
export async function getComments(collName, docID) {
  try {
    const comments = await fetch(
      `${url}/comment?collName=${collName}&docID=${docID}`
    );
    return await comments.json();
  } catch (error) {
    logError(error);
  }
}
export async function getQuotes() {
  try {
    const quotes = await fetch(`${url}/quote`);
    return await quotes.json();
  } catch (error) {
    logError(error);
  }
}
export async function postComment(comment) {
  try {
    const result = await fetch(`${url}/comment/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token")
      },
      body: JSON.stringify(comment)
    });
    return await result.json();
  } catch (error) {
    logError(error);
  }
}

export async function commentHelpful(commentID) {
  try {
    const result = await fetch(`${url}/comment/${commentID}/like`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    });
    return await result.json();
  } catch (error) {
    logError(error);
  }
}

export async function commentNotHelpful(commentID) {
  try {
    const result = await fetch(`${url}/comment/${commentID}/dislike`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    });
    return await result.json();
  } catch (error) {
    logError(error);
  }
}

export async function commentAllowToWrite(dateID){
  const res = await fetch(`${url}/comment/allow-to-write?dateID=${dateID}`,{
    headers: {
      token: localStorage.getItem("token")
    },
  })
  const resJson = await res.json()
  return resJson
}

export async function getLeaderBoardTop({year, limit, skip, filter}) {
  try {
    const result = await fetch(`${url}/leaderboard/top?year=${year}&limit=${limit}&skip=${skip}&filter=${filter || ""}`);
    return await result.json();
  } catch (error) {
    logError(error)
  }
}

export async function getLeaderboardUser(userID, year) {
  const res = await fetch(`${url}/leaderboard/leaderboard-user?userID=${userID}&year=${year}`, {
    headers: {
      token: localStorage.getItem("token"),
    }
  }
  )
  const resJson = await res.json()
  return resJson
}

export async function getAllBandDates(params) {
  try {
    const response = await fetch(`${url}/band-date/all?${Object.keys(params)?.filter(key => typeof params[key] != 'undefined')
      .map(key => `${key}=${params[key]}`).join('&')}`);
    return await response.json();
  } catch (error) {
    logError(error);
  }
}

export async function getMostRecentReviews(page) {
  try {
    const response = await fetch(`${url}/comment/most-recents?page=${page}`);
    return await response.json();
  } catch (error) {
    logError(error);
  }
}

export async function getReviewFilters() {
  try {
    const response = await fetch(`${url}/comment/review-filters`);
    return await response.json();
  } catch (error) {
    logError(error);
  }
}

export async function updateAwardReviews(data) {
  try {
    const result = await fetch(`${url}/award/review/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token")
      },
      body: JSON.stringify(data)
    });
    return await result.json();
  } catch (error) {
    logError(error);
  }
}

export async function updateSectionReview(data) {
  try {
    const result = await fetch(`${url}/section/review/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.token,
      },
      body: JSON.stringify(data)
    });
    return await result.json();
  } catch (error) {
    logError(error);
  }
}

export async function getResult(id) {
  try {
    const result = await fetch(`${url}/award/${id}`);
    return await result.json();
  } catch (error) {
    logError(error)
  }
}

export async function getProfile() {
  try {
    const profile = await fetch(`${url}/user`, {
      method: "GET",
      headers: {
        token: localStorage.getItem("token"),
        Accept: "application/json"
      }
    });
    return await profile.json();
  } catch (error) {
    logError(error);
  }
}

export async function updateProfile(data) {
  try {
    const profile = await fetch(`${url}/user/profile`, {
      method: "PUT",
      headers: {
        token: localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
    return await profile.json();
  } catch (error) {
    logError(error);
  }
}

export async function logout() {
  try {
    var res = await fetch(`${url}/user/logout`, {
      method: "DELETE",
      headers: {
        token: localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    });
    res = await res.json();
    if (res) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
    return res;
  } catch (error) {
    logError(error);
  }
}

// unused!
export async function getFestivalLastYear(slug) {
  try {
    var response = await fetch(`${url}/dates/festivalLastYear/${slug}`);
    if(response.status !== 200) {
      window.alert('Could not get data!');
      return null;
    }
    response = await response.json()
    return response.data;
  } catch (error) {
    logError(error);
  }
}

export async function getDateByYear(year) {
  try {
    let res = await fetch(`${url}/dates/year/${year || 'all'}`);
    return await res.json();
  } catch (error) {
    logError(error);
  }
}

export async function getBandDateByDate(dateId) {
  try {
    let res = await fetch(`${url}/band-date/festival?id=${dateId}`);
    return await res.json();
  } catch (error) {
    logError(error);
  }
}

export async function getBandsByFestival(festivalSlug) {
  try {
    let res = await fetch(`${url}/organization/search/festival?slug=${festivalSlug}`);
    return await res.json();
  } catch (error) {
    logError(error);
  }
}

export async function getSectionsByBandDate(bandDateId) {
  try {
    const res = await fetch(`${url}/section/search/banddate/${bandDateId}`);
    return await res.json();
  } catch (error) {
    logError(error);
  }
}

export async function getFestivalWhole(slug) {
  try {
    var response = await fetch(`${url}/festival/${slug}`);
    if(response.status !== 200) {
      window.alert('Could not get data!');
      return null;
    }
    response = await response.json()
    return response;
  } catch (error) {
    logError(error);
  }
}

export async function getPastYears (slug) {
  try {
    let pastYears = await fetch(`${url}/dates/pastYears/${slug}`)
    if (pastYears.status == 404) {
      window.alert('Could not get data!');
      return null;
    }
    pastYears = await pastYears.json();
    if (pastYears.code != 0) return [];
    return pastYears.data;
  } catch (err) {
    await logError(err);
  }
}

export async function getFestival(slug) {
  try {
    var festival = await fetch(`${url}/dates/festival/${slug}`);
    if(festival.status==404) {
      window.alert('Could not get data!');
      return null;
    }
    festival = await festival.json();
    if (festival.code != 0) return []; // error
    return festival.data;
  } catch (error) {
    logError(error);
  }
}

export async function getFestivalsData(year) {
  try {
    const data = await fetch(`${url}/dates/festivals?year=${year}`);
    return await data.json();
  } catch (error) {
    logError(error);
  }
}

export async function getLastYearOfAllFestivals() {
  try {
    const response = await fetch(`${url}/festival/list/last-of-all`);
    return await response.json();
  } catch (error) {
    logError(error);
  }
}

export async function like (value, docId) {
  const res = await fetch(`${url}/like/like`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify({
      value: value,
      docID: docId,
    })
  });
  return await res.json();
}

export async function getHistory(slug) {
  try {
    const history = await fetch(`${url}/festival/${slug}/history`);
    return await history.json();
  } catch (error) {
    logError(error);
  }
}
export async function getNotifSettings() {
  try {
    const response = await fetch(`${url}/user/profile/notification-settings`, {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    });
    return await response.json();
  } catch (error) {
    logError(error);
  }
}
export async function updateNotifSettings(body) {
  try {
    let res = await fetch(`${url}/user/update-notification-settings`, {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
      method: 'POST',
      body: JSON.stringify(body),
    });
    return await res.json();
  } catch (error) {
    logError(error);
  }
}
export async function getNewsLettersList() {
  try {
    const list = await fetch(`${url}/news-letter/list`);
    return await list.json();
  } catch (error) {
    logError(error);
  }
}

export async function getNewsLetterBySlug(slug) {
  try {
    const letter = await fetch(`${url}/news-letter/by-slug/${slug}`);
    return await letter.json();
  } catch (error) {
    logError(error);
  }
}

export async function getEvent(slug) {
  try {
    var data = await fetch(`${url}/event/${slug}`);
    data = await data.json();
    if (data.event) {
      if (data.event.sDate) {
        data.event.sDate=moment(data.event.sDate).utc().format('llll')
      }
      if (data.event.eDate) {
        data.event.eDate=moment(data.event.eDate).utc().format('llll')
      }
    }
    return data;
  } catch (error) {
    logError(error);
  }
}

export async function getFestivalsList() {
  try {
    const list = await fetch(`${url}/festival/list/names`);
    return await list.json();
  } catch (error) {
    logError(error);
  }
}

export async function getOneNews(id) {
  try {
    var oneNews = await fetch(`${url}/news/${id}`);
    oneNews = await oneNews.json();
    return oneNews;
  } catch (error) {
    logError(error);
  }
}

export async function getBlog(slug) {
  try {
    let data = await fetch(`${url}/blog/${slug}`);
    data = await data.json();
    if(data && data.blog && data.blog.pDate){
      data.blog.pDate=moment(data.blog.pDate).utc().format('llll')
    }
    return data;
  } catch (error) {
    logError(error);
  }
}

export async function getCostumeBand(slug) {
  try {
    let data = await fetch(`${url}/band-date/slug/${slug}`,{
      method: "GET",
      headers: {
        token: localStorage.getItem("token"),
        Accept: "application/json",
      },
    });
    data = await data.json();
    if (data.bandDate && data.bandDate.lDate) {
      data.bandDate.lDate = dateConvertWithoutHour(data.bandDate.lDate);
    }
    if (data.bandDate && data.bandDate.rdDate) {
      data.bandDate.rdDate = dateConvertWithoutHour(data.bandDate.rdDate);
    }
    if (data.sections && data.sections.length > 0) {
      for(let section of data.sections) {
        if (section.lDate) section.lDate = dateConvertWithoutHour(section.lDate);
        if (section.rdDate) section.rdDate = dateConvertWithoutHour(section.rdDate);
      }
    }
    if (data.lines && data.lines.length > 0) {
      for(let line of data.lines) {
        if (line.lDate) line.lDate = dateConvertWithoutHour(line.lDate);
        if (line.rdDate) line.rdDate = dateConvertWithoutHour(line.rdDate);
      }
    }
    if (data.events) {
      data.events.map(event => event.sDate = dateConverter(event.sDate))
    }
    return data;
  } catch (error) {
    logError(error);
  }
}

export async function getBandDates(data) {
  try {
    let res = await fetch(`${url}/band-date/filter-reviews?carnival=${data.carnival}&year=${data.year}&bandId=${data.bandId}`, {
      method: "GET",
      headers: {
        token: localStorage.getItem("token"),
        Accept: "application/json",
      },
    });
    if(res.status==404) {
      window.alert('Could not get data!');
      return null;
    }
    res = await res.json();
    return res;
  } catch (error) {
    logError(error);
  }
}

export async function getReviews(bd) {
  try {
    let res = await fetch(`${url}/comment/band-date-Comments?bd=${bd}`, {
      method: "GET",
      headers: {
        token: localStorage.getItem("token"),
        Accept: "application/json",
      },
    });
    if(res.status==404) {
      window.alert('Could not get data!');
      return null;
    }
    res = await res.json();
    return res;
  } catch (error) {
    logError(error);
  }
}

export async function postBandDateScoreReview(data) {
  try {
    const result = await fetch(`${url}/band-date/score/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token")
      },
      body: JSON.stringify(data)
    });
    return await result.json();
  } catch (error) {
    logError(error);
  }
}

export async function reportComment(data) {
    try{
        const res = await fetch(`${url}/report/report-comment?reportee=${data['reporteeId']}&commentId=${data['commentId']}`,
            {headers: {token: localStorage.getItem("token")}}
        );
        return await res.json();
    } catch (err) {
        console.error(err);
    }
}

export async function getCostumeBandsByYear(data) {
  try {
    const bands = await fetch(`${url}/band-date/filtered?year=${data.year}&band=${data.band}&carnival=${data.carnival}&sections=${data.sections}`);
    return await bands.json();
  } catch (error) {
    logError(error);
  }
}

export async function getDesigner(slug) {
  try {
    var designer = await fetch(`${url}/organization/${slug}`);
    designer = await designer.json();
    return designer;
  } catch (error) {
    logError(error);
  }
}
// get user info
export async function logInByGoogleToken(data) {
  try {
    var result = await fetch(`${url}/user/login/google/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        tokenId: data.tokenId
      }
    });
    result = await result.json();
    return result;
  } catch (error) {
    logError(error);
  }
}

export async function logInByOtp(data) {
  try {
    const fetchRes = await fetch(`${url}/user/login/otp`, {
      method: "POST",
      body: JSON.stringify({
        otp: data.otp
      }),
      headers: {
        "Content-Type": "application/json",
      }
    });
    const fetchJson = await fetchRes.json();
    return fetchJson;
  } catch (err) {
    logError(err);
    throw err;
  }
}

export async function createPost(collName, data) {
  try {
    const response = await fetch(`${url}/${collName}/create`, {
      method: "POST",
      headers: {
        token: localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
    return await response.json();
  } catch (error) {
    logError(error);
  }
}

export async function getFormData(collName) {
  try {
    const data = await fetch(`${url}/${collName}/form/params`, {
      method: "GET",
      headers: {
        token: localStorage.getItem('token'),
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    });
    return await data.json();
  } catch (error) {
    logError(error)
  }
}

export async function getTravel(id) {
  try {
    const data = await fetch(`${url}/travel/find/${id}`, {
      method: "GET",
      headers: {
        token: localStorage.token,
      },
    });
    return await data.json();
  } catch (error) {
    logError(error);
  }
}

export async function getFilterData(collName) {
  try {
    const data = await fetch(`${url}/${collName}/filter/params`, {
      method: "GET",
      headers: {
        token: localStorage.getItem('token'),
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    })
    return await data.json();
  } catch (error) {
    logError(error)
  }
}

export async function getRandomAd({page,node}) {
  try {
    const ad = await fetch(`${url}/ad/random${page && node ? `?page=${page}&node=${node}` : ''}`);
    return await ad.json();
  } catch (error) {
    logError(error);
  }
}
export async function getLandingFestival() {
  try {
    let festivalDate = await fetch(`${url}/dates/landing`);
    festivalDate = await festivalDate.json();
    return festivalDate;
  } catch (error) {
    logError(error)
    // return error
  }
}
export async function getWatchList() {
  try {
    const watchList = await fetch(`${url}/watch-list`, {
      method: "GET",
      headers: {
        token: localStorage.getItem('token'),
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    });
    return await watchList.json();
  } catch (error) {
    logError(error)
    // return error
  }
}
export async function addWatchList(caseId, collName) {
  try {
    if (!caseId || !collName)
      return { code: -2, msg: "please send caseId & collName" };
    const data = { caseId, collName };
    const response = await fetch(`${url}/watch-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.token,
      },
      body: JSON.stringify(data)
    });
    return await response.json();
  } catch (error) {
    logError(error);
  }
}
export async function removeWatchList(id) {
  try {
    if (!id) return { code: -2, msg: "please send id of your Watch List" };
    const response = await fetch(`${url}/watch-list/remove/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.token,
      },
    });
    return await response.json();
  } catch (error) {
    logError(error)
    // return error
  }
}
export async function deleteTravel(id) {
  try {
    const res = await fetch(`${url}/travel/delete/${id}`, {
      headers: {
        token: localStorage.token,
      }
    });
    return await res.json();
  } catch (error) {
    logError(error);
  }
}
export async function checkInWatchList(caseId, collName) {
  try {
    if (!caseId) return { code: -2, msg: "send your caseId" };
    const response = await fetch(`${url}/watch-list/check?caseId=${caseId}&collName=${collName}`, {
      headers: {
        token: localStorage.token,
      },
    });
    return await response.json();
  } catch (error) {
    logError(error);
  }
}
export async function bandsCompare(slugs) {
  try {

    var response = await fetch(`${url}/band-date/compare?slugs=${slugs}`)
    response = await response.json();
    if (response.code != 0) throw response.msg
    return response.data
  }
  catch (error) {
    logError(error)
  }
}

export async function getStatic(slug) {
  try {
    const res = await fetch(`${url}/statics?slug=${slug}`)
    const resJson = await res.json();
    if (resJson.code === 0) {
      return resJson.data
    } else {
      return null;
    }
  } catch (err) {
    logError(err)
  }
}
export async function searchBand(text, sort, skip, limit) {
  try{
    var res = await fetch(`${url}/organization/search/band?q=${text}&sort=${sort}&skip=${skip}&limit=${limit}`);
    res=await res.json();
    if (res.code === 0) return {bands: res.bands, hasMore: res.hasMore};
    return {bands: [], hasMore: false};
    } catch(err) {
      logError(err);
      return {bands: [], hasMore: false};
  }
}
export async function uploadProfilePicture(data){
  try {
    var res=await fetch(`${url}/user/profile/picture`,{
      method:'POST',
      headers: {
        token: localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({picture:data})
    })
    res=await res.json()
    return res
  } catch (error) {
    logError(error)
    return {code:-1,msg:"Something is wrong, Please try again"}
  }

}

export async function removeProfilePicture(){
  try {
    var res=await fetch(`${url}/user/profile/picture`,{
      method:'DELETE',
      headers: {
        token: localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    })
    res=await res.json()
    return res
  } catch (error) {
    logError(error)
    return {code:-1,msg:"Something is wrong, Please try again"}
  }
}

export async function getBandBySlug(slug){
  try {
    var res=await fetch(`${url}/organization/${slug}`,{
      method:'Get',
      headers: {
        token: localStorage.getItem("token"),
      },
    })
    res=await res.json()
    return res
  } catch (error) {
    logError(error)
    return {code:-1,msg:"Something is wrong, Please try again"}
  }
}

export async function searchAll (value) {
  let res = await fetch( `${url}/festival/search/all?q=${value}`);
  res = await res.json();
  return res;
}

// export async function searchCarnival(q){
//   var res=await fetch( `${url}/dates/search/name?q=${q}`)
//   res=await res.json()
//   return res
// }
export async function searchFestivals(q){
  var res = await fetch( `${url}/festival/search/all?q=${q}`)
  res = await res.json()
  return res;
}
export async function logInByFacebookToken(data){
  try {
    var res=await fetch(`${url}/user/login/facebook`,{
      method:"POST",
      headers: {
        "Content-Type": "application/json",
        accesstoken: data.accessToken
      },
      body:JSON.stringify({userID:data.userID})
    })
    res=await res.json()
    return res
  } catch (error) {
    return {code:-1,msg:error.message,data:null}
  }
}
export async function getYearsHaveFestival(){
  var res=await fetch( `${url}/festival/list/years`)
  res=await res.json()
  return res
}
export async function getBandsFestivalDate(id){
  var res=await fetch( `${url}/band-date/festival?id=${id}`)
  res=await res.json()
  return res
}
export async function getListFestivals(){
  var res=await fetch( `${url}/festival/list/all`)
  res=await res.json()
  return res
}
export async function getUserInfo(userID){
  try {
    var res=await fetch(`${url}/user/${userID}`,{
            method: "GET",
            headers: {
            token: localStorage.getItem('token'),
            Accept: "application/json",
            "Content-Type": "application/json"
          },
    })
    res=await res.json()
    return res  
  } catch (error) {
    console.log(error)
    return {code:-1,msg:"Something is wrong, Please try again"}
  }
}
export async function followUser(id){
  try {
    var res=await fetch(`${url}/follow`,{
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token")
      },
      body:JSON.stringify({userId:id})
    })
    res=await res.json()
    return res
  } catch (error) {

    return {code:-1,msg:"Something is wrong, Please try again"}
  }

}
export async function unfollowUser(id){
  try {
    var res=await fetch(`${url}/follow/unfollow`,{
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token")
      },
      body: JSON.stringify({userId:id})
    })
    res=await res.json()
    return res
  } catch (error) {
    console.log(error)
    return {code:-1,msg:"Something is wrong, Please try again"}
  }
}
export async function getFollowing(){
  try {
    const token=localStorage.getItem("token")
    if(!token) return {code:-2,msg:"Somthing is wrong."}
    var res=await fetch(`${url}/follow/following`,{
      method:'Get',
      headers: {
        token: token,
      },
    })
    res=await res.json()
    return res
  } catch (error) {
    console.log(error)
    return {code:-1,msg:"Something is wrong, Please try again"}
  }
}
export async function getFollowers(){
  try {
    var res=await fetch(`${url}/follow/follower`,{
      method:'Get',
      headers: {
        token: localStorage.getItem("token"),
      },
    })
    res=await res.json()
    return res
  } catch (error) {
    console.log(error)
    return {code:-1,msg:"Something is wrong, Please try again"}
  }
}

export async function postAwardComment(comment) {
  try {
    const result = await fetch(`${url}/award-comment/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token")
      },
      body: JSON.stringify(comment)
    });
    return await result.json();
  } catch (error) {
    logError(error);
  }
}

export async function getAllAwardComment(awardID) {
  try{
    const res = await fetch(`${url}/award-comment/?awardID=${awardID}`)
    const resJson = await res.json()
    return resJson
  } catch (err) {
    logError(err);
  }
}

export async function postBlogComment(comment) {
  try {
    const result = await fetch(`${url}/blog-comment/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token")
      },
      body: JSON.stringify(comment)
    });
    return await result.json();
  } catch (error) {
    logError(error);
  }
}

export async function getAllBlogComment(blogID) {
  try{
    const res = await fetch(`${url}/blog-comment/?blogID=${blogID}`)
    const resJson = await res.json()
    return resJson
  } catch (err) {
    logError(err);
  }
}

export async function getMyComments() {
  const res = await fetch(`${url}/comment/mine`, {
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    },
  })
  return await res.json()
}

export async function deleteMyComment(commentID) {
  const res = await fetch(`${url}/comment/mine/${commentID}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    },
  })
  return await res.json()
}

export async function updateMyComment(commentID, comment) {
  try {
    const result = await fetch(`${url}/comment/mine/${commentID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token")
      },
      body: JSON.stringify(comment)
    });
    return await result.json();
  } catch (error) {
    logError(error);
  }
}

export async function getCommentFormInitData() {
  const res = await fetch(`${url}/comment/form-init-data`)
  const resJson = await res.json()
  return resJson
}
export async function getChaserAbout() {
  var res = await fetch(`${url}/dynamic/chaserDescription`);
  res = await res.json();
  return res;
}

export async function getCurrentYearRevealDates(sortBy, skip, q) {
  let res = await fetch(`${url}/dates/revealDates?sortBy=${sortBy}&skip=${skip}&q=${q}`);
  return await res.json();
}
export async function searchUsersByName(name) {
  var res = await fetch(`${url}/user/search/byname?name=${name}`, {
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
  });
  return await res.json();
}

function dateConvertWithoutHour(data){
  return moment(data).utc().format("ddd, MMM D, YYYY"); 
}
function dateConverter(data) {
  return moment(data).utc().format('llll')
}
